import React from "react"
import styled from "styled-components"
import { Box } from "../../commonElements"

const Option = styled(Box)`
  height: 2.6rem;
  width: 2.6rem;
  border-radius: 50%;
  border: 1px solid
    ${props =>
      props.selected ? props.theme.colors.accent : props.theme.colors.border};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${props =>
    props.selected ? props.theme.colors.accent : "transparent"};
  color: ${props =>
    props.selected ? props.theme.colors.white : props.theme.colors.text};
`

const QuantityList = props => {
  return (
    <Box flexDirection="row" gap=".5rem" flexWrap="wrap">
      {props.options.map(option => {
        return (
          <Option
            key={`toolQuantity${option}`}
            selected={option === props.checkedValue}
            onClick={() => props.valueChanged(option)}
          >
            {option}
          </Option>
        )
      })}
    </Box>
  )
}

export default QuantityList
